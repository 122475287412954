import React from 'react';
import store from "../store";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import * as STATIC from '../config/static';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import 'moment-lunar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/vi";
import _ from 'lodash'
import {
  DatePicker
} from '@material-ui/pickers';
moment.locale("vi");
const throttle = require('lodash.throttle');
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PList = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
const CList = [
  { name: 'Hạng bất kỳ', value: '' },
  { name: 'Hạng phổ thông', value: 'economy' },
  { name: 'Hạng phổ thông đặc biệt', value: 'premiumeconomy' },
  { name: 'Hạng thương gia', value: 'business' },
  { name: 'Hạng nhất', value: 'first' }
]
const DLIST = [
  {
    PlaceId: 'VN-sky',
    PlaceName: 'Việt Nam',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: '-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'SGN-sky',
    PlaceName: 'Thành phố Hồ Chí Minh',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'SGNV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'HAN-sky',
    PlaceName: 'Hà Nội',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'HANV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'DAD-sky',
    PlaceName: 'Đà Nẵng',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'DADV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'CXR-sky',
    PlaceName: 'Cam Ranh (Nha Trang)',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'NHAV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'PQC-sky',
    PlaceName: 'Phú Quốc',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'PQCV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'HUI-sky',
    PlaceName: 'Huế',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'HUIV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'DLI-sky',
    PlaceName: 'Đà Lạt',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'DLIV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'HPH-sky',
    PlaceName: 'Hải Phòng',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'HPHV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: 'VCA-sky',
    PlaceName: 'Cần Thơ',
    CountryId: 'VN-sky',
    RegionId: '',
    CityId: 'VCAV-sky',
    CountryName: 'Việt Nam'
  },
  {
    PlaceId: "VDO-sky",
    PlaceName: "Van Don International",
    CountryId: "VN-sky",
    RegionId: "",
    CityId: "VDOA-sky",
    CountryName: "Vietnam",
  }
]
let _this = this;
class FlightSearchForm extends React.Component {
  state = {
    outboundDate: moment(),
    inboundDate: '',
    originPlace: DLIST[1],
    destinationPlace: DLIST[2],
    "adults": "1",
    "children": "0",
    "infants": "0",
    openPicker: 0,
    cabinClass: '',
    options: [],
    oOpen: false,
    dOpen: false,
    oLoading: false,
    dLoading: false,
    oDialog:false,
    message:''
  };
  constructor(props) {
    super(props)
    _this = this;
    this.servicesWorker = _.throttle(this.servicesWorker, 500);
  }
  handleChange = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  handleChangePerson = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  onDateChange = date => {
    if (this.state.openPicker == 1)
      this.setState({ outboundDate: date, openPicker: 0 });
    else
      this.setState({ inboundDate: date, openPicker: 0 });
  }
  async loadSearch(search, name) {
    let res = await STATIC.getDataRapid(STATIC.API_RAPID_PLACE, { query: search });

    if (res && res.Places) {
      let result = _.unionBy(res.Places, DLIST, 'PlaceId');
      _this.setState({ options:result  });
    //  console.log(result);
    //  console.log(res.Places);
    }
    if (name == 'originPlace') {
      if (res && res.Places) {
        _this.setState({ oOpen: true });
      }
      _this.setState({ oLoading: false });
    }
    if (name == 'destinationPlace') {
      if (res && res.Places) {
        _this.setState({ dOpen: true });
      }
      _this.setState({ dLoading: false });
    }
  }
  onOriginChange = (event, value) => {
    _this.setState({'originPlace':value});
  }
  onDesChange = (event, value) => {
    _this.setState({'destinationPlace':value});
  }
  onSearchChange = (value, name) => {
    if (value.length >= 2) {
      if (name == 'originPlace') {

        _this.setState({ oLoading: true });
      }
      if (name == 'destinationPlace') {

        _this.setState({ dLoading: true });
      }
      //console.log('Calling worker');
      _this.servicesWorker(value, name);
    }
    else if(value.length==0){
      if (name == 'originPlace') {

        _this.setState({ originPlace: false });
      }
      if (name == 'destinationPlace') {

        _this.setState({ destinationPlace: false });
      }
    }


  }
  servicesWorker = (value, name) => {
    _this.loadSearch(value, name);

  }
  submitSearch(e)
  {
    e.preventDefault();

    let { outboundDate, inboundDate, originPlace, destinationPlace, adults, children, infants, cabinClass } = this.state;
    if(!originPlace)
    {
      _this.setState({oDialog:true,message:'Hãy chọn nơi đi!'})
      return;
    }
    if(!destinationPlace)
    {
      _this.setState({oDialog:true,message:'Hãy chọn nơi đến!'})
      return;
    }
    let data = {
		"country": "VN",
		"currency": "VND",
		"locale": "vi-VN",
		"originPlace": originPlace.PlaceId,
		"destinationPlace": destinationPlace.PlaceId,
		"outboundDate": _this.state.outboundDate.format('YYYY-MM-DD'),
    "inboundDate" : _this.state.inboundDate?_this.state.inboundDate.format('YYYY-MM-DD'):'',
		"adults": _this.state.adults,
    "children": _this.state.children,
		"infants": _this.state.infants,
    "cabinClass":_this.state.cabinClass
  }
  if(!data.cabinClass)
  {
    delete data.cabinClass;
  }
  if(_this.props.onSubmit)
  {
    _this.props.onSubmit(data);
  }
  }

  render() {

    const { classes } = this.props;
    let { outboundDate, inboundDate, originPlace, destinationPlace, adults, children, infants, cabinClass, openPicker, options, oOpen, dOpen, oLoading, dLoading,oDialog } = this.state;
    let width = this.props.width;
    if (this.props.width > 980)
      width = 980;
    let HC = STATIC.calSize;
    let fVeryLarge = width * 0.12;
    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
    let fSmaller = width * 0.015;
    let cWidth = 592;
    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='fr'>

        <div style={{ backgroundColor: STATIC.MyStyle.TEXT_COLOR, color: 'white', display: 'flex', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: HC(20, width) + 'px ' + HC(10, width) + 'px' }}>
          <Grid container justify="center" alignItems='center'>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={8} >
                  <Grid container justify='space-between' direction='column' style={{ height: '100%' }}>
                    <Grid item >
                      <Autocomplete
                        autoComplete
                        options={options.length == 0 ? DLIST : options}
                        getOptionLabel={option => option.PlaceName + ' (' + option.PlaceId.replace('-sky', '') + ')'}
                        disableOpenOnFocus
                        loading={true}
                        open={oOpen}
                        onOpen={() => {
                          this.setState({ oOpen: true });
                        }}
                        onClose={() => {
                          this.setState({ oOpen: false });
                        }}
                        defaultValue={DLIST[1]}
                        onChange={this.onOriginChange}
                        renderInput={params => (
                          <TextField {...params}
                            InputLabelProps={{ style: {}, ...params.InputLabelProps }}
                            InputProps={{
                              style: { backgroundColor: 'white', minHeight: 54, height: HC(70, width) }, ...params.InputProps, endAdornment: (
                                <React.Fragment>
                                  {oLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                            label="Thành phố hoặc sân bay đi" variant="outlined"
                            onChange={(e) => _this.onSearchChange(e.target.value, 'originPlace')} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: HC(10, width) }} >
                      <Autocomplete

                        options={options.length == 0 ? DLIST : options}
                        getOptionLabel={option => option.PlaceName + ' (' + option.PlaceId.replace('-sky', '') + ')'}
                        disableOpenOnFocus
                        loading={true}
                        open={dOpen}
                        defaultValue={DLIST[2]}
                        onOpen={() => {
                          this.setState({ dOpen: true });
                        }}
                        onClose={() => {
                          this.setState({ dOpen: false });
                        }}
                        onChange={this.onDesChange}
                        renderInput={params => {

                          return <TextField  {...params} InputLabelProps={{ style: {}, ...params.InputLabelProps }}
                            InputProps={{ style: { backgroundColor: 'white', minHeight: 54, height: HC(70, width) }, ...params.InputProps, endAdornment: (
                              <React.Fragment>
                                {dLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ) }}
                            label="Thành phố hoặc sân bay dến" onChange={(e) => _this.onSearchChange(e.target.value, 'destinationPlace')} variant="outlined" fullWidth />
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingLeft: HC(10, width), marginTop: HC(cWidth <= width ? 0 : 20, width) }}>
                  <Grid container direction='row' justify='center'>
                    <Grid item>
                      <div style={{ color: STATIC.MyStyle.TEXT_COLOR, position: 'relative', width: cWidth <= width ? HC(140, width) : HC(280, width), height: cWidth <= width ? HC(140, width) : HC(280, width), display: 'flex', flexDirection: 'column', padding: HC(5, width) + 'px ' + HC(5, width) + 'px', backgroundColor: STATIC.MyStyle.BG_COLOR }}>
                        <div style={{ textAlign: 'center', fontSize: cWidth <= width ? fSmall : fSmall * 2 }} >Ngày đi</div>
                        <ButtonBase onClick={() => this.setState({ openPicker: 1 })} focusRipple style={{ color: "white", position: 'relative', flexGrow: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: HC(10, width) + 'px ' + HC(10, width) + 'px', backgroundColor: 'black' }} color="primary" size="large" variant="contained"  >
                          <div style={{ color: 'gold', fontSize: cWidth <= width ? fSmall : fSmall * 2 }}>{moment(outboundDate).format('MMM')}</div>
                          <div style={{ color: 'white', fontSize: cWidth <= width ? fTitle : fTitle * 2 }}>{moment(outboundDate).format('D')}</div>
                          <div style={{ color: 'gold', fontSize: cWidth <= width ? fSmaller : fSmaller * 2 }}>{moment(outboundDate).lunar().format('DD/MM')} AL</div>
                        </ButtonBase>
                      </div>
                    </Grid>
                    <Grid item style={{ marginLeft: HC(10, width) }}>
                      <div style={{ color: STATIC.MyStyle.TEXT_COLOR, position: 'relative', width: cWidth <= width ? HC(140, width) : HC(280, width), height: cWidth <= width ? HC(140, width) : HC(280, width), display: 'flex', flexDirection: 'column', padding: HC(5, width) + 'px ' + HC(5, width) + 'px', backgroundColor: STATIC.MyStyle.BG_COLOR }}>
                        <div style={{ textAlign: 'center', fontSize: cWidth <= width ? fSmall : fSmall * 2 }} >Ngày về</div>
                        <ButtonBase onClick={() => this.setState({ openPicker: 2 })} focusRipple style={{ color: "white", position: 'relative', flexGrow: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: HC(10, width) + 'px ' + HC(10, width) + 'px', backgroundColor: 'black' }} color="primary" size="large" variant="contained"  >
                          {inboundDate == '' ? <div><AddIcon style={{ fontSize: cWidth <= width ? fLarge : fLarge * 2 }} /></div> : false}
                          {inboundDate != '' ? <div style={{ color: 'gold', fontSize: cWidth <= width ? fSmall : fSmall * 2 }}>{moment(inboundDate).format('MMM')}</div> : false}
                          {inboundDate != '' ? <div style={{ color: 'red', fontSize: cWidth <= width ? fTitle : fTitle * 2 }}>{moment(inboundDate).format('D')}</div> : false}
                          {inboundDate != '' ? <div style={{ color: 'gold', fontSize: cWidth <= width ? fSmaller : fSmaller * 2 }}>{moment(inboundDate).lunar().format('DD/MM')} AL</div> : false}

                        </ButtonBase>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: width > 458 ? 'row' : 'column', justifyContent: 'center', marginTop: HC(10, width) }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><FormControl variant="filled" style={{ borderRadius: HC(10, width), marginLeft: HC(10, width) }}>
                <Select
                  value={adults}
                  onChange={this.handleChangePerson('adults')}
                  displayEmpty
                  IconComponent={Person}
                  SelectDisplayProps={{ style: { padding: 10, paddingRight: 24 } }}
                  style={{ backgroundColor: STATIC.MyStyle.BG_COLOR, padding: (HC(5, width) + 'px ' + HC(10, width) + 'px'), width: "100%", border: 'none' }}
                >
                  {PList.map((n, index) => {
                    if (index == 0) return false;
                    return (<MenuItem key={index} value={n}>
                      {n}
                    </MenuItem>)
                  })}
                </Select>
              </FormControl>
                <FormControl variant="filled" style={{ borderRadius: HC(10, width), marginLeft: HC(10, width) }}>
                  <Select
                    value={children}
                    onChange={this.handleChangePerson('children')}
                    displayEmpty
                    IconComponent={AccessibilityNewIcon}
                    SelectDisplayProps={{ style: { padding: 10, paddingRight: 24 } }}
                    style={{ backgroundColor: STATIC.MyStyle.BG_COLOR, padding: (HC(5, width) + 'px ' + HC(10, width) + 'px'), width: "100%", border: 'none' }}
                  >
                    {PList.map((n, index) => {

                      return (<MenuItem key={index} value={n}>
                        {n}
                      </MenuItem>)
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="filled" style={{ borderRadius: HC(10, width), marginLeft: HC(10, width) }}>
                  <Select
                    value={infants}
                    onChange={this.handleChangePerson('infants')}
                    displayEmpty
                    IconComponent={ChildCareIcon}
                    SelectDisplayProps={{ style: { padding: 10, paddingRight: 24 } }}
                    style={{ backgroundColor: STATIC.MyStyle.BG_COLOR, padding: (HC(5, width) + 'px ' + HC(10, width) + 'px'), width: "100%", border: 'none' }}
                  >
                    {PList.map((n, index) => {

                      return (<MenuItem key={index} value={n}>
                        {n}
                      </MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <FormControl variant="filled" style={{ borderRadius: HC(10, width), marginLeft: HC(10, width), width: 250 }}>
                  <Select
                    value={cabinClass}
                    onChange={this.handleChangePerson('cabinClass')}
                    displayEmpty
                    IconComponent={AirlineSeatReclineExtraIcon}
                    SelectDisplayProps={{ style: { padding: 10, paddingRight: 24 } }}
                    style={{ backgroundColor: STATIC.MyStyle.BG_COLOR, padding: (HC(5, width) + 'px ' + HC(10, width) + 'px'), width: "100%", border: 'none' }}
                  >
                    {CList.map((n, index) => {

                      return (<MenuItem key={index} value={n.value}>
                        {n.name}
                      </MenuItem>)
                    })}
                  </Select>
                </FormControl></div>
            </Grid>
            <Grid item style={{ marginTop: HC(20, width) }}>
              <ButtonBase onClick={e=>this.submitSearch(e)} focusRipple style={{ color: "white", position: 'relative', display: 'flex', padding: HC(10, width) + 'px ' + HC(10, width) + 'px', backgroundColor: 'black' }} color="primary" size="large" variant="contained"  >
                <div><SearchIcon style={{ fontSize: cWidth <= width ? fNormal : fNormal * 2, marginRight: HC(10, width) }} /></div>
                <div style={{ fontSize: cWidth <= width ? fNormal : fNormal * 2 }}> Tìm</div>
              </ButtonBase>
            </Grid>
          </Grid>
          <DatePicker style={{ display: 'none' }} open={openPicker == 1} minDate={moment()} value={outboundDate} onChange={this.onDateChange} onClose={() => this.setState({ openPicker: 0 })} />
          <DatePicker style={{ display: 'none' }} open={openPicker == 2} minDate={outboundDate} value={inboundDate != '' ? inboundDate : ''} onChange={this.onDateChange} onClose={() => this.setState({ openPicker: 0 })} />
        </div>
        <Dialog
        open={oDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>this.setState({oDialog:false})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{this.state.message}</DialogTitle>

        <DialogActions>
          <Button onClick={()=>this.setState({oDialog:false})} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      </MuiPickersUtilsProvider>



    );
  }
}
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 }
];
FlightSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(FlightSearchForm));
