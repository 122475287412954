import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App'
import './style/style.css';
import 'normalize.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import pink from '@material-ui/core/colors/pink';
import * as serviceWorker from './serviceWorker';
import 'react-notifications/lib/notifications.css';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_vi from "./trans/vi/common.json";
import common_en from "./trans/en/common.json";


i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            vi: {
                common: common_vi
            },
        },  // React already does escaping
});
const theme = createMuiTheme({
    palette: {
        primary: {main:'#fa8a19'},
        secondary: {main:"#fa8a19"},
    }
});
function Root() {
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
              <I18nextProvider i18n={i18next}>

                <App/>

            </I18nextProvider>
            </BrowserRouter>
        </MuiThemeProvider>
    );
}
render(<Root />, document.querySelector('#root'));
serviceWorker.unregister();
