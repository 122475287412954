import React, { Component } from 'react';
import store from '../store';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import BackIcon from '@material-ui/icons/ChevronLeft';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import createHistory from 'history/createBrowserHistory';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import * as STATIC from '../config/static';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TopBar from '../components/TopBar';

let _this;
class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      email: '',
      remember: false,
      forgotPass: false,
      ACCOUNT: null,
      showForgot: false,
      showSent: false,
      loading:false,

    }
    _this = this;
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    // this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
    //window.addEventListener("resize", this.updateDimensions);
    //this.loadBible();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    _this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  }



  handleChange = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  handleChangeCheckbox = name => event => {

    this.setState({
      [name]: event.target.checked
    });
  }
  handleKeypress = event => {
    console.log(event.key);
    if (event.key === "Enter") {
      _this.LoginUser(_this.state.username,_this.state.password,event)
    }
  }

  handleForgot = () => event => {
    event.preventDefault();
    this.setState({
      forgotPass: true
    });
  }

  ForgotPasswordFirebase = () => event => {
    event.preventDefault();
    store.dispatch({ type: 'ERROR_LOGIN', MESSAGE: '' });
    firebase.auth().sendPasswordResetEmail(this.state.email).then(function() {
      alert("Email Sent");
      window.location.reload();
    }).catch(function(error) {
      store.dispatch({ type: 'ERROR_LOGIN', MESSAGE: error.message });
    });
  }

  handleResetPass = (newPassword) => event => {
    event.preventDefault();
    const actionCode = store.getState().actionCode;
    firebase.auth().verifyPasswordResetCode(actionCode).then(function(email) {
      firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function(resp) {
        alert("Was reset the password");
        store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
      }).catch(function(error) {
        alert(error.message);
        store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
      });
    }).catch(function(error) {
      alert(error.message);
      store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
    });
    this.setState({
      reset1: '',
      reset2: ''
    });
  }

  BackResetPassword = () => event => {
    event.preventDefault();
    this.setState({
      forgotPass: false
    });
  }


  async LoginUser (username, password, event) {
    if(event&&event.preventDefault)
    event.preventDefault();
    // if (username == 'demo' && password == 'demo') {
    //   let ACCOUNT = { name: 'Jacky', class: 'Super' };
    //   localStorage.setItem('ACCOUNT', JSON.stringify(ACCOUNT));
    //   store.dispatch({ type: 'ACCOUNT', ACCOUNT });
    // }
    if(!username||username.trim().length==0 || !password || password.trim().length==0)
    {
      STATIC.NotificationManager.error('Please enter your login info!');
      return;
    }
    await _this.setState({loading:true});
    let res = await STATIC.postFormData(STATIC.API_USER_CHECKLOGIN,{username,password});
    console.log(res);
    if(res && res.status==1)
    {
      let ACCOUNT = res.data.user;
      let TOKEN = res.data.token;
      localStorage.setItem('ACCOUNT', JSON.stringify(ACCOUNT));
      localStorage.setItem('TOKEN', JSON.stringify(TOKEN));
      store.dispatch({ type: 'ACCOUNT', ACCOUNT });


    }
    else {
      _this.setState({MESSAGE:_this.props.t('login.not_correct')})

    }
    await _this.setState({loading:false});

  }
  sendForgot = (username, email) => event => {
    event.preventDefault();
    if (email == 'demo@superbrain.vn' && username == 'demo') {
      _this.setState({ showSent: true });
    }
    else {
      _this.setState({ MESSAGE: _this.props.t('login.not_found')});
    }

  }

  render() {
    const { classes } = this.props;
    const { username, password, email, remember, wWidth, wHeight } = this.state;
    const { t } = this.props;
    let width = STATIC.DWIDTH - 10;
    if (wWidth < STATIC.DWIDTH) {
      width = wWidth - 10;
    }

    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;

    if (store.getState().ACCOUNT) {
      return <Redirect to='/' />
    }
    if (this.state.showForgot) {
      return (
        <div className={classes.root}>
          <div className={classes.middle}>
            <TopBar width={width} />
            {store.getState().ACCOUNT && createHistory().location.pathname !== '/' ? <Redirect to='/' /> : ''}
            <div className={classes.square} style={{ padding: 5 }} elevation={4}>

              <div style={{ padding: (STATIC.calSize(60, STATIC.DWIDTH, width) + 'px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px'), flexGrow: 1, position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.4)', zIndex: -1 }} />
                <div style={{ textAlign: 'center', marginBottom: STATIC.calSize(60, STATIC.DWIDTH, width) }}>

                  <div style={{ textAlign: 'center', margin: 0, color: '#053f22', fontSize: fTitle, fontFamily: 'UTMP' }}>
                    Password Reset<br />___ Information ___
                    </div>


                </div>
                {!this.state.showSent ? (<div>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', height: STATIC.calSize(65, STATIC.DWIDTH, width), justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', marginBottom: STATIC.calSize(10, STATIC.DWIDTH, width) }}>
                        <div style={{ width: STATIC.calSize(65, STATIC.DWIDTH, width), height: STATIC.calSize(65, STATIC.DWIDTH, width), background: '#8dc63f', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img
                            src="assets/web/icon_mail.png"
                            style={{ width: STATIC.calSize(25, STATIC.DWIDTH, width), height: 'auto', margin: 'auto', display: 'block' }} />
                        </div>
                        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                          <input
                            value={email}
                            type="text"
                            placeholder="Email"
                            onChange={this.handleChange('email')}
                            style={{ border: 'none', height: STATIC.calSize(65, STATIC.DWIDTH, width), width: '100%', padding: '0px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px', color: '#053f22', fontSize: fNormal }} />
                        </div>
                      </div>

                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', height: STATIC.calSize(65, STATIC.DWIDTH, width), justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', marginBottom: STATIC.calSize(10, STATIC.DWIDTH, width) }}>
                        <div style={{ width: STATIC.calSize(65, STATIC.DWIDTH, width), height: STATIC.calSize(65, STATIC.DWIDTH, width), background: '#8dc63f', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img
                            src="assets/web/icon_acc.png"
                            style={{ width: STATIC.calSize(20, STATIC.DWIDTH, width), height: 'auto', margin: 'auto', display: 'block' }} />
                        </div>
                        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                          <input
                            value={username}
                            type="text"
                            placeholder={t('login.username')}
                            onChange={this.handleChange('username')}

                            style={{ border: 'none', height: STATIC.calSize(65, STATIC.DWIDTH, width), width: '100%', padding: '0px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px', color: '#053f22', fontSize: fNormal }} />
                        </div>
                      </div>

                    </Grid>

                  </Grid>
                  {this.state.MESSAGE ? (<p
                    style={{ color: 'red', marginTop: STATIC.calSize(10, STATIC.DWIDTH, width), fontSize: fNormal, textAlign: 'center' }}>
                    {this.state.MESSAGE}
                  </p>)
                    : ''}

                  <Grid container direction="row" alignItems="center" justify="center" style={{ marginTop: STATIC.calSize(25, STATIC.DWIDTH, width) }}>
                    <Grid item xs style={{ display: "flex", flexDirection: 'column' }}>


                    </Grid>

                    <Grid item xs>

                      <Button style={{ color: "white", padding: (STATIC.calSize(6, STATIC.DWIDTH, width) + 'px ' + STATIC.calSize(16, STATIC.DWIDTH, width) + 'px'), minWidth: 0, backgroundColor: '#8dc63f', fontWeight: 'bold', fontSize: fNormal, float: 'right', textTransform: "capitalize", fontWeight: 'normal' }} color="primary" size="large" variant="contained" onClick={this.sendForgot(username, email)}>
                        {t('login.send')}
                                              </Button>
                    </Grid>
                  </Grid>
                </div>
                ) : (<Grid container direction="row" alignItems="center" justify="center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }} >
                  <Grid item xs>
                    <div style={{ textAlign: 'center', margin: STATIC.calSize(100, STATIC.DWIDTH, width) + "px 0", color: 'rgb(5, 63, 34)', fontSize: fTitle }} dangerouslySetInnerHTML={{__html: t('login.send_message')}} >

</div>


                  </Grid></Grid>)}
                <div onClick={() => this.setState({ MESSAGE: '', showForgot: false, showSent: false })} style={{ width: STATIC.calSize(40, STATIC.DWIDTH, width), height: STATIC.calSize(40, STATIC.DWIDTH, width), position: 'absolute', top: STATIC.calSize(20, STATIC.DWIDTH, width), left: STATIC.calSize(20, STATIC.DWIDTH, width), borderRadius: STATIC.calSize(20, STATIC.DWIDTH, width), backgroundColor: 'rgb(0, 104, 56)', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                  <BackIcon style={{ color: 'white', fontSize: fTitle }} />
                </div>


              </div>
              <Grid container >
                <Grid item xs style={{ margin: STATIC.calSize(20, STATIC.DWIDTH, width), position: 'relative' }}>
                  <div style={{ textAlign: 'center', margin: 0, color: 'white', fontSize: fSmall }}>
                    Copyright © Superbrain Group 2019
                </div>
                </Grid>
              </Grid>

            </div>
          </div>

        </div>
      );
    }
    return (
      <div className={classes.root}>
        <div className={classes.middle}>
          <TopBar width={width} />
          {store.getState().ACCOUNT && createHistory().location.pathname !== '/' ? <Redirect to='/' /> : ''}
          <div className={classes.square} style={{ padding: 5 }} elevation={4}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item xs style={{ margin: STATIC.calSize(20, STATIC.DWIDTH, width), position: 'relative' }}>
                <div style={{ textAlign: 'center', margin: 0, color: '#053f22', fontFamily: 'UTMP', fontSize: fLarge }}>
                  SUPERBRAIN ONLINE<br />______ PRACTICE ______
                          </div>

              </Grid>
            </Grid>
            <div style={{ padding: (STATIC.calSize(60, STATIC.DWIDTH, width) + 'px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px'), flexGrow: 1, position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.4)', zIndex: -1 }} />
              <div style={{ textAlign: 'center', marginBottom: STATIC.calSize(60, STATIC.DWIDTH, width) }}>
                <img
                  src="assets/web/logo_color.png"
                  style={{ height: 'auto', width: STATIC.calSize(160, STATIC.DWIDTH, width) }} />
                <div style={{ textAlign: 'center', margin: 0, color: '#053f22', fontSize: fTitle }}>
                  {t('login.title')}
                </div>
                <Link
                  to="/trial"
                  style={{ textAlign: 'center', margin: 0, color: '#053f22', fontSize: fSmall }}>
                  {t('login.not_member')}
                </Link>
              </div>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div style={{ display: 'flex', height: STATIC.calSize(65, STATIC.DWIDTH, width), justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', marginBottom: STATIC.calSize(10, STATIC.DWIDTH, width) }}>
                    <div style={{ width: STATIC.calSize(65, STATIC.DWIDTH, width), height: STATIC.calSize(65, STATIC.DWIDTH, width), background: '#8dc63f', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img
                        src="assets/web/icon_acc.png"
                        style={{ width: STATIC.calSize(20, STATIC.DWIDTH, width), height: 'auto', margin: 'auto', display: 'block' }} />
                    </div>
                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                      <input
                        value={username}
                        type="text"
                        placeholder={t('login.username_email')}
                        onChange={this.handleChange('username')}
                        onKeyPress={this.handleKeypress}
                        style={{ border: 'none', height: STATIC.calSize(65, STATIC.DWIDTH, width), width: '100%', padding: '0px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px', color: '#053f22', fontSize: fNormal }} />
                    </div>
                  </div>

                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', height: STATIC.calSize(65, STATIC.DWIDTH, width), justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', marginBottom: STATIC.calSize(10, STATIC.DWIDTH, width) }}>
                    <div style={{ width: STATIC.calSize(65, STATIC.DWIDTH, width), height: STATIC.calSize(65, STATIC.DWIDTH, width), background: '#8dc63f', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img
                        src="assets/web/icon_lock.png"
                        style={{ width: STATIC.calSize(20, STATIC.DWIDTH, width), height: 'auto', margin: 'auto', display: 'block' }} />
                    </div>
                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                      <input
                        value={password}
                        type="password"
                        placeholder={t('login.password')}
                        onChange={this.handleChange('password')}
                        onKeyPress={this.handleKeypress}
                        style={{ border: 'none', height: STATIC.calSize(65, STATIC.DWIDTH, width), width: '100%', padding: '0px ' + STATIC.calSize(20, STATIC.DWIDTH, width) + 'px', color: '#053f22', fontSize: fNormal }} />
                    </div>
                  </div>

                </Grid>

              </Grid>
              {this.state.MESSAGE ? (<p
                style={{ color: 'red', marginTop: STATIC.calSize(10, STATIC.DWIDTH, width), fontSize: fNormal, textAlign: 'center' }}>
                {this.state.MESSAGE}
              </p>)
                : ''}

              <Grid container direction="row" alignItems="center" justify="center" style={{ marginTop: STATIC.calSize(25, STATIC.DWIDTH, width) }}>
                <Grid item xs style={{ display: "flex", flexDirection: 'column' }}>

                  <FormControlLabel
                    control={
                      <Checkbox

                        checked={this.state.remember}
                        onChange={this.handleChangeCheckbox('remember')}
                        icon={<CheckBoxOutlineBlankIcon style={{ width: STATIC.calSize(28, STATIC.DWIDTH, width), height: STATIC.calSize(28, STATIC.DWIDTH, width) }} />}
                        checkedIcon={<CheckBoxIcon style={{ width: STATIC.calSize(28, STATIC.DWIDTH, width), height: STATIC.calSize(28, STATIC.DWIDTH, width) }} />}
                        style={{
                          color: "#053f22", padding: STATIC.calSize(12, STATIC.DWIDTH, width)
                        }}
                        value="remember"
                      />
                    }
                    label={<span style={{ color: '#053f22', fontSize: fSmall }}>{t('login.remember')}</span>}
                    style={{ margin: 0 }}
                  />

                  <a onClick={() => this.setState({ showForgot: true })} style={{ color: "#053f22", cursor: 'pointer', fontSize: fNormal }}>
                    {t('login.forgot')}
                  </a>
                </Grid>

                <Grid item xs>

                  <Button disabled={this.state.loading} style={{ color: "white", padding: (STATIC.calSize(6, STATIC.DWIDTH, width) + 'px ' + STATIC.calSize(16, STATIC.DWIDTH, width) + 'px'), minWidth: 0, backgroundColor: '#8dc63f', fontWeight: 'bold', fontSize: fNormal, float: 'right', textTransform: "capitalize", fontWeight: 'normal' }} color="primary" size="large" variant="contained" onClick={(e)=>this.LoginUser(username, password,e)}>
                    {t('login.signin')}
                                            </Button>
                </Grid>
              </Grid>
            </div>
            <Grid container >
              <Grid item xs style={{ margin: STATIC.calSize(20, STATIC.DWIDTH, width), position: 'relative' }}>
                <div style={{ textAlign: 'center', margin: 0, color: 'white', fontSize: fSmall }}>
                  Copyright © Superbrain Group 2019
                </div>
              </Grid>
            </Grid>

          </div>
        </div>

      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(Login));
