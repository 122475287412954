import React from "react";
import store from "../store";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import "react-intl-tel-input/dist/main.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import hideOnScroll from '../components/hideonscroll'
import MenuIcon from '@material-ui/icons/Menu';

import { NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Link } from "react-router-dom";

import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import Paper from '@material-ui/core/Paper';

import { Redirect } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { FIREBASE } from "../config/firebase";
import Divider from '@material-ui/core/Divider';
import * as STATIC from '../config/static';
import _ from 'lodash';
import {Helmet} from "react-helmet";

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE);
}
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validatePhone(phone) {
  var re = /^([0-9]{6,20})$/;
  return re.test(phone);
};

const firestore = firebase.firestore();
//let IS_NAMING = window.location.hostname.toLowerCase()=='www1.namethegiraffe.com'?true:false;
//IS_NAMING = true;


var _this;

class ExchangeTicket extends React.Component {
  state = {
    viewDidLoad: false,
  };
  constructor() {
    super();
    _this = this;
    this.state={}

  }
  updateDimensions() {
    //console.log(window.innerWidth);
    // _this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
  }
  componentDidMount() {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);




    // this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
    //window.addEventListener("resize", this.updateDimensions);
    //this.loadBible();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    _this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  }
  handleChange = name => event => {
    //event.preventDefault();
    _this.setState({ [name]: event.target.value });
  };



  render() {
    const { classes,t } = this.props;
    const { loading, wWidth, wHeight } = this.state;
    let width = STATIC.DWIDTH;
    if (wWidth < STATIC.DWIDTH) {
      width = wWidth;
    }
    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
let HC = STATIC.calSize;
/*
<div
  data-skyscanner-widget="FlightSearchWidget"
  data-associate-id="vegiare"
  data-locale="vi-VN"
  data-market="VN"
  data-currency="VND"
  data-colour="#000000"
  data-multi-city-max-flights-number="0"
  data-flights-cabin-classes="false"
></div>
<script src="https://widgets.skyscanner.net/widget-server/js/loader.js" async></script>
*/
    //console.log(ACCOUNT);
    return (
      <div className={classes.root}>
        <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }} />

        <div className={classes.middle}>

          <TopBar width={width} history={this.props.history} />
          

          <div className={classes.square} style={{ maxWidth: width, backgroundColor: 'rgba(0,0,0,0.8)' }}>
            <div style={{ padding: (STATIC.calSize(40, STATIC.DWIDTH, width) + 'px ' + STATIC.calSize(20, width) + 'px'), flexGrow: 1, position: 'relative' }}>
              <div>
                <Grid container direction="column" alignItems="center" justify="center" >

                </Grid>
              </div>

            </div>
          </div>
          <Footer width={width} />
        </div>

      </div>
    );
  }
}

ExchangeTicket.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(ExchangeTicket));
