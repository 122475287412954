import React from 'react';
import store from "../store";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import * as STATIC from '../config/static';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import Person from '@material-ui/icons/Person';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import 'moment-lunar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "moment/locale/vi";
import _ from 'lodash'
import {
  DatePicker
} from '@material-ui/pickers';
import styled from 'styled-components';
moment.locale("vi");
const throttle = require('lodash.throttle');
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopDiv = styled.div`
  top: -.375rem;
  position: absolute;
    width: .75rem;
    height: .375rem;
    overflow: hidden;
    &:after {
      right: -50%;
  bottom: 200%;
  left: -50%;
  position: relative;
      content: "";
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateZ(0);
      border: .375rem solid #fff;
      border-radius: .75rem;
      box-shadow: inset 0 1px 3px 0 rgba(37,32,31,.3);
  }
`;
let _this = this;
class SearchResults extends React.Component {

  constructor(props) {
    super(props)
    _this = this;
    this.state = {
      oDialog:false,
      message:'',
      loading:props.loading,
      resultData:props.searchData
    };
    console.log(props.searchData);
    //this.servicesWorker = _.throttle(this.servicesWorker, 500);
  }
  handleChange = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  handleChangePerson = name => event => {
    event.preventDefault();
    this.setState({
      [name]: event.target.value
    });
  }
  // componentWillReceiveProps(props)
  // {
  //   if(props.loading!=this.state.loading)
  //   this.setState({loading:props.loading})
  // }

  getLegsInfo(leg,resultData)
  {
//console.log(leg.OriginStation);
//console.log(leg.DestinationStation);
     leg.OriginStation = _.find(resultData.Places,{Id:leg.OriginStation});

     leg.DestinationStation = _.find(resultData.Places,{Id:leg.DestinationStation});
     let tStops = [];
     leg.Stops.forEach(f=>{
       tStops.push(_.find(resultData.Places,{Id:f}));
     })
     leg.Stops = tStops;
     let tCarriers = [];
     leg.Carriers.forEach(f=>{
       tCarriers.push(_.find(resultData.Carriers,{Id:f}));
     })
     leg.Carriers = tCarriers;
     leg.FlightNumbers.forEach(f=>{
       f.Carrier=_.find(resultData.Carriers,{Id:f.CarrierId});
     })
   // Duration Departure Arrival Stops Carriers
   leg.Segments = [];
   leg.SegmentIds.forEach(id=>{
     leg.Segments.push(resultData.Segments[id]);
   })
  }


  render() {

    const { classes,message,searchData,loading } = this.props;

    let resultData = searchData;
    let { oDialog } = this.state;
    let width = this.props.width;
    if (this.props.width > 980)
      width = 980;
    let HC = STATIC.calSize;
    let fVeryLarge = width * 0.12;
    let fLarge = width * 0.06;
    let fTitle = width * 0.04;
    let fNormal = width * 0.03;
    let fSmall = width * 0.02;
    let fSmaller = width * 0.015;
    let cWidth = 592;

      // let flight = resultData.Itineraries[0];
      // //console.log(flight.OutboundLegId);
      //  flight.OutboundLeg = _.find(resultData.Legs,{Id:flight.OutboundLegId});
      //   this.getLegsInfo(flight.OutboundLeg,resultData);
      //
      //
      // flight.InboundLeg = _.find(resultData.Legs,{Id:flight.InboundLegId});
      //
      // if(flight.InboundLeg)
      // {
      //     this.getLegsInfo(flight.InboundLeg,resultData);
      // }
      // flight.PricingOption = flight.PricingOptions[0];
      // console.log(flight);
      //PricingOption.Price QuoteAgeInMinutes DeeplinkUrl


    //  console.log(OutboundLeg);


    return (
      <div>

        <div style={{ backgroundColor: STATIC.MyStyle.BG_TRANS, color: STATIC.MyStyle.TEXT_COLOR, display: 'flex',flexDirection:'column', alignItems: 'center', maxWidth: STATIC.DWIDTH, width: width, padding: HC(20, width) + 'px ' + HC(10, width) + 'px' }}>
{resultData.Itineraries.length==0?<div style={{fontSize:fNormal}}>Không tìm thấy chuyến bay nào</div>:<div style={{fontSize:fSmall}}>Giá được hiển thị đã bao gồm thuế và phụ phí.</div>}

            <Grid container direction='column'>

                {resultData.Itineraries.map((flight,index)=>{
                  //console.log(flight.OutboundLegId);
                   flight.OutboundLeg = _.cloneDeep(_.find(resultData.Legs,{Id:flight.OutboundLegId})) ;
                    this.getLegsInfo(flight.OutboundLeg,resultData);


                  flight.InboundLeg = _.cloneDeep(_.find(resultData.Legs,{Id:flight.InboundLegId}));
                //  console.log(flight.InboundLeg);
                  if(flight.InboundLeg)
                  {
                      this.getLegsInfo(flight.InboundLeg,resultData);
                  }
                  flight.PricingOption = flight.PricingOptions[0];
                  flight.Bounds = [flight.OutboundLeg];
                  if(flight.InboundLeg)
                  {
                    flight.Bounds.push(flight.InboundLeg);
                  }


                //  console.log(flight);
                  //PricingOption.Price QuoteAgeInMinutes DeeplinkUrl
                  return (<Grid key={index} item xs={12}  style={{marginTop:HC(index>0?20:0,width)}}>

                      <div style={{display:'flex',flexDirection:'row',minHeight:100}}>
                          <div  style={{width:width*0.8,flexDirection:'column',display:'flex',justifyContent:'center',backgroundColor:STATIC.MyStyle.BG_COLOR,padding:HC(10,width),borderRadius:HC(10,width),border:'2px solid '+STATIC.MyStyle.TEXT_COLOR,borderRight:0}}>
                                  {flight.Bounds.map((bound,index2)=>{
                                    let cName = '';
                                    bound.Carriers.forEach(c=>{

                                      if(cName=='')
                                      cName = c.Name;
                                      else
                                      cName += '\n+ '+c.Name;
                                    })
                                    let sCode = '';
                                    bound.Stops.forEach(s=>{

                                      if(sCode=='')
                                      sCode = s.Code;
                                      else
                                      sCode += ' '+s.Code;
                                    })
                                    return (<div key={index2} className={classes.centerFlex} style={{flexDirection:'row',marginTop:HC(index2>0?10:0,width)}}>
                                      <div style={{width:'20%',fontSize:fSmaller}}>

                                      {bound.Stops.length==0?<img src={bound.Carriers[0].ImageUrl}/>:cName}
                                    </div>
                                    <div className={classes.centerFlex}  style={{flexGrow:'1',flexDirection:'row'}}>
                                        <div style={{flexDirection:'column',display:'flex',width:'30%'}} >
                                            <div style={{fontSize:fNormal,textAlign:'right'}}>
                                              {moment(bound.Departure).format('HH:mm')}
                                            </div>
                                            <div style={{fontSize:fSmall,textAlign:'right'}}>
                                              {bound.OriginStation.Code}
                                            </div>
                                            <div style={{fontSize:fSmaller,textAlign:'right'}}>
                                              {bound.OriginStation.Name}
                                            </div>
                                        </div>
                                        <div className={classes.centerFlex} style={{flexDirection:'column',width:'40%',padding:'0px '+HC(10,width)+'px'}} >
                                          <div style={{fontSize:fSmall}}>
                                              {moment().startOf('day').add(bound.Duration, 'minutes').format('H[g] m[p]')}
                                          </div>
                                          <div className={classes.centerFlex} style={{position:'relative',flexDirection:'row',width:'100%'}}>
                                            <div>
                                              <FlightTakeoffIcon />
                                            </div>
                                            <div className={classes.centerFlex} style={{position:'relative',flexDirection:'row',height:5,flexGrow:1}}>
                                              <div style={{position:"absolute",top:2,left:0,right:0,height:2,backgroundColor:'gray',zIndex:0}}/>
                                              {bound.Stops.map((m,index3)=>{
                                                return <div key={index3} style={{width:8,height:8,borderRadius:4,backgroundColor:'red',zIndex:1,marginRight:HC(5,width)}}/>
                                              })}

                                            </div>
                                            <div>
                                              <FlightLandIcon />
                                            </div>

                                          </div>
                                          <div style={{fontSize:fSmall,textAlign:'center'}}>
                                              {bound.Stops.length==0? 'Bay thẳng':bound.Stops.length+' điểm dừng '+sCode}
                                          </div>
                                        </div>
                                        <div  style={{flexDirection:'column',display:'flex',width:'30%'}} >
                                            <div style={{fontSize:fNormal}}>
                                              {moment(bound.Arrival).format('HH:mm')}
                                            </div>
                                            <div style={{fontSize:fSmall,textAlign:'left'}}>
                                              {bound.DestinationStation.Code}
                                            </div>
                                            <div style={{fontSize:fSmaller,textAlign:'left'}}>
                                              {bound.DestinationStation.Name}
                                            </div>
                                        </div>
                                    </div>
                                    </div>)
                                  })}
                          </div>

                          <div className={classes.centerFlex} style={{width:width*0.2,padding:HC(10,width),backgroundColor:STATIC.MyStyle.BG_COLOR,borderRadius:HC(10,width),border:'2px solid '+STATIC.MyStyle.TEXT_COLOR,flexDirection:'column'}}>
                              <div style={{fontSize:fSmall,fontWeight:'bold'}}>{STATIC.numberWithCommas(flight.PricingOption.Price.toFixed(0))} ₫</div>
                                {loading?<div><CircularProgress color="inherit" size={20} /></div>:<ButtonBase onClick={e=>this.props.showBuyTicket(flight)} focusRipple style={{ color: "white",borderRadius:HC(5,width), position: 'relative', display: 'flex', padding: HC(5, width) + 'px ' + HC(15, width) + 'px', backgroundColor: 'black' }} color="primary" size="large" variant="contained"  >

                                  <div style={{ fontSize:  fSmall }}>Chọn</div>
                                </ButtonBase>}
                          </div>
                      </div>
                  </Grid>)
                })}
            </Grid>
        </div>
        <Dialog
        open={oDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>this.setState({oDialog:false})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{this.state.message}</DialogTitle>

        <DialogActions>
          <Button onClick={()=>this.setState({oDialog:false})} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </div>



    );
  }
}
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 }
];
SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(SearchResults));
