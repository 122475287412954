import React from 'react';
import store from '../store';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Content from '../components/Content';

import * as STATIC from '../config/static';
import CircularProgress from '@material-ui/core/CircularProgress';

import {NotificationContainer} from 'react-notifications';
import _ from 'lodash';
import moment from 'moment';
import { FIREBASE } from "../config/firebase";
import firebase from "firebase/app";
import "firebase/firestore";

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE);
}
const firestore = firebase.firestore();
const adminPath = ['/myadmin','/myadmin/login','/myadmin/banner'];
class ClippedDrawer extends React.Component {
  constructor() {
    super();
    this.state = {};

  }

  async componentDidMount()
  {
    store.subscribe(()=>{
      this.setState({});
    });
    this.loadData();
    this.loadLangSetting();

  }

  async loadLangSetting(){
    let LANG = localStorage.getItem('LANG');
    if(LANG)
    {
      LANG = LANG;
    }
    else {
      LANG = 'en';
      localStorage.setItem('LANG',LANG);
    }
    this.props.i18n.changeLanguage(LANG);

    store.dispatch({ type: 'LANG', LANG });
  }

  async loadData(){
    let pathName = window.location.pathname.toLowerCase();

    if(adminPath.findIndex(a=>a==pathName)>-1)
    {
      let ADMINACCOUNT = localStorage.getItem('ADMINACCOUNT');
      if(ADMINACCOUNT)
      {
        ADMINACCOUNT = JSON.parse(ADMINACCOUNT);
        store.dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT });
      }

    }
    else {
      let ACCOUNT = localStorage.getItem('ACCOUNT');
      if(ACCOUNT)
      {
        ACCOUNT = JSON.parse(ACCOUNT);
        store.dispatch({ type: 'ACCOUNT', ACCOUNT});


          setTimeout(()=>{
            //this.checkAccountToken(ACCOUNT);
          },2000)

      }



    }

    store.dispatch({ type: 'LOADING', LOADING:false });
    //this.setState({loading:false});
  }
  async checkAccountToken(ACCOUNT){
      let TOKEN = localStorage.getItem('TOKEN');
      if(TOKEN)
      {
        TOKEN = JSON.parse(TOKEN);
        if(TOKEN.id)
        {
          let tDoc = await firestore.collection("tokens").doc(TOKEN.id).get();
          if(tDoc.exists)
          {
            let tData = tDoc.data();
            //console.log(tData.id_user,TOKEN.id_user,ACCOUNT.id);
            if(tData.id_user == TOKEN.id_user&&TOKEN.id_user==ACCOUNT.id&&moment(tData.expired).isAfter(moment()))
            {

              //console.log('Token Valid');
            }
            else
            {
              firestore.collection("tokens").doc(TOKEN.id).delete();
              localStorage.removeItem('TOKEN');
              localStorage.removeItem('ACCOUNT');
              store.dispatch({ type: 'ACCOUNT', ACCOUNT:false });
            }
          }
          else {
            localStorage.removeItem('TOKEN');
            localStorage.removeItem('ACCOUNT');
            store.dispatch({ type: 'ACCOUNT', ACCOUNT:false });
          }
        }
        else {
          localStorage.removeItem('TOKEN');
          localStorage.removeItem('ACCOUNT');
          store.dispatch({ type: 'ACCOUNT', ACCOUNT:false });
        }

      }
      else {
        localStorage.removeItem('ACCOUNT');
        store.dispatch({ type: 'ACCOUNT', ACCOUNT:false });
      }

  }

  render() {
    const { classes } = this.props;

  //  const ACCOUNT = store.getState().ACCOUNT;
  if(store.getState().LOADING)
  {
    return (
      <div className={classes.root}>
      <div className={classes.middle} >
              <center>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <div>
                  <img src="assets/web/logo_color.png" style={{width: "100%",height:"auto"}} />
                  </div>
                  <div>
                  <CircularProgress color="secondary"/>
                  </div>

              </div>

              </center>
          </div>
          <div style={{position:'fixed',right:0,bottom:0,fontSize:8,zIndex:1}}>v1.0.0.0</div>
      </div>
    );
  }
    return (
      <div>

         <Content />

        <NotificationContainer/>
        <div style={{position:'fixed',right:0,bottom:0,fontSize:8,zIndex:1}}>v1.0.0.0</div>
      </div>
    );
  }
}

ClippedDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(ClippedDrawer));
