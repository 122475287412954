import { createStore } from 'redux';


const reducer = (state, action) => {

    if (action.type === "ACCOUNT") {
        return {
            ...state,
            ACCOUNT: action.ACCOUNT
        }
    }
    if (action.type === "LASTDAY") {
        return {
            ...state,
            LASTDAY: action.LASTDAY
        }
    }
    if (action.type === "ADMINACCOUNT") {
        return {
            ...state,
            ADMINACCOUNT: action.ADMINACCOUNT
        }
    }
    if (action.type === "LOADING") {
        return {
            ...state,
            LOADING: action.LOADING
        }
    }
    if (action.type === "DATA_COURSES") {
        return {
            ...state,
            DATA_COURSES: action.DATA_COURSES
        }
    }
    if (action.type === "INNERS") {
        return {
            ...state,
            INNERS: action.INNERS
        }
    }
    if (action.type === "SOUND") {
        return {
            ...state,
            SOUND: action.SOUND
        }
    }
    if (action.type === "LANG") {
        return {
            ...state,
            LANG: action.LANG
        }
    }
    if (action.type === "PERS") {
        return {
            ...state,
            PERS: action.PERS
        }
    }

    return state;
}

export default createStore(reducer, {ACCOUNT:false,ADMINACCOUNT:false,INNERS:false,SOUND:false,USER:false,LASTDAY:false,LANG:'en',LOADING:true,DATA_COURSES:false,PERS:{class:false,practice:true,lesson:false,challenge:false} });
