import React from 'react'
import { Switch, Route } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import HomePage2 from '../pages/HomePage2'
import ExchangeTicket from '../pages/ExchangeTicket'
import BuyTicket from '../pages/BuyTicket'
import Login from '../pages/Login'

import AdminHomePage from '../pages/AdminHomePage'
import AdminLogin from '../pages/AdminLogin'

const Content = () => (
  <Switch>
    <Route exact path='/' component={HomePage} />

    <Route  path='/login' component={Login} />
    <Route  path='/home' component={HomePage2} />
    <Route  path='/nhuongve' component={ExchangeTicket} />
    <Route  path='/ex_ticket' component={ExchangeTicket} />
      <Route  path='/muave' component={BuyTicket} />
      <Route  path='/buy_ticket' component={BuyTicket} />
    <Route exact path='/myadmin' component={AdminHomePage} />
    <Route  path='/myadmin/login' component={AdminLogin} />

  </Switch>
)

export default Content;
