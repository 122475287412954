import React from "react";
import store from "../store";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "react-intl-tel-input/dist/main.css";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '../components/Toolbar';
import hideOnScroll from '../components/hideonscroll'
import pink from '@material-ui/core/colors/pink';
import { NotificationManager} from 'react-notifications';

import { Link } from "react-router-dom";

import { Redirect } from 'react-router-dom';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader  from '@material-ui/core/CardHeader';
import FaceIcon from '@material-ui/icons/Face';
import StarsIcon from '@material-ui/icons/Stars';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { FIREBASE } from "../config/firebase";

const DWIDTH = 640;
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE);
}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function validatePhone (phone){
  var re = /^([0-9]{6,20})$/;
    return re.test(phone);
};

const firestore = firebase.firestore();
//let IS_NAMING = window.location.hostname.toLowerCase()=='www1.namethegiraffe.com'?true:false;
 //IS_NAMING = true;
const AutoHideAppBar = hideOnScroll(AppBar)
const styles = theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
    padding: 0
  },
  avatar: {
    backgroundColor: pink[500],
  },
  linknav: {
    textDecoration: 'none'
  },
  toolbar: {
    height: theme.spacing.unit * 3
  },
  card: {
    marginBottom: theme.spacing.unit * 3,
    borderRadius: 5
  },
  root: {
    paddingTop: theme.spacing.unit * 10,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: 500
  }
});
var _this;

class AdminHomePage extends React.Component {
  state = {
    viewDidLoad: false,

    loading:true,
    ADMINACCOUNT:null,
  };
  constructor() {
    super();
    _this = this;

  }
  updateDimensions() {
    //console.log(window.innerWidth);
    // _this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    //console.log('Home Page did mount');
    // this.setState({
    //   innerWidth: window.innerWidth,
    //   innerHeight: window.innerHeight
    // });
    //window.addEventListener("resize", this.updateDimensions);
    //this.loadBible();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    _this.setState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  }
  handleChange = name => event => {
    //event.preventDefault();
    _this.setState({ [name]: event.target.value });
  };

syncData(){
  console.log('Sync Data');
}
  logoutClick()
  {
    localStorage.removeItem('ADMINACCOUNT');
    store.dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:false });
    _this.setState({ADMINACCOUNT:null});
  }


  render() {
    const { classes } = this.props;

    let ADMINACCOUNT = store.getState().ADMINACCOUNT;

    return (
      <div>
      <Toolbar/>
      <div className={classes.root}>

      <Link to='/sbadmin/course' className={classes.linknav}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <AllInboxIcon />
            </Avatar>}
            title={"Course"} />
        </Card>
      </Link>
      <Link to='#' className={classes.linknav}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <FaceIcon />
            </Avatar>}
            title={"Students"} />
        </Card>
      </Link>
      <Link to='/sbadmin/challenges' className={classes.linknav}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <StarsIcon />
            </Avatar>}
            title={"Challenges"} />
        </Card>
      </Link>
      <Link to='/sbadmin/video' className={classes.linknav}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <AssessmentIcon />
            </Avatar>}
            title={"Video"} />
        </Card>
      </Link>

      <Link to='/sbadmin/guest' className={classes.linknav}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <SupervisorIcon />
            </Avatar>}
            title={"Guest Users"} />
        </Card>
      </Link>
      <Link to='#' className={classes.linknav} onClick={()=>this.syncData()}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar aria-label="Recipe" className={classes.avatar}>
              <AllInboxIcon />
            </Avatar>}
            title={"Sync Data"} />
        </Card>
      </Link>






      </div>

      {!ADMINACCOUNT?<Redirect to='/sbadmin/login' />:false}
      </div>
    );
  }
}

AdminHomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminHomePage);
