import React, { Component } from 'react';
import store from '../store';

import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LockIcon from '@material-ui/icons/Lock';

import createHistory from 'history/createBrowserHistory';

import Paper from '@material-ui/core/Paper';

import { Redirect } from 'react-router-dom';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        background: 'linear-gradient(45deg, #F8BBD0 10%, #9FA8DA 80%)',
        overflow: 'hidden',
        position: 'absolute',
        display: 'table',
        height: '100%',
        width: '100%'
    },
    middle: {
        display: 'table-cell',
        verticalAlign: 'middle'
    },
    square: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 320,
        padding: theme.spacing.unit * 5
    },
    textField: {
        minWidth: 200,
    }
});

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            forgotPass: false,
            reset1: '',
            reset2: '',

        }
    }
    componentDidMount() {

    }

    handleChange = name => event => {
        event.preventDefault();
        this.setState({
            [name]: event.target.value
        });
    }

    handleForgot = () => event => {
        event.preventDefault();
        this.setState({
            forgotPass: true
        });
    }

    ForgotPasswordFirebase = () => event => {
        event.preventDefault();
        store.dispatch({ type: 'ERROR_LOGIN', MESSAGE: '' });
        firebase.auth().sendPasswordResetEmail(this.state.email).then(function () {
            alert("Email Sent");
            window.location.reload();
        }).catch(function (error) {
            store.dispatch({ type: 'ERROR_LOGIN', MESSAGE: error.message });
        });
    }

    handleResetPass = (newPassword) => event => {
        event.preventDefault();
        const actionCode = store.getState().actionCode;
        firebase.auth().verifyPasswordResetCode(actionCode).then(function (email) {
            firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function (resp) {
                alert("Was reset the password");
                store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
            }).catch(function (error) {
                alert(error.message);
                store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
            });
        }).catch(function (error) {
            alert(error.message);
            store.dispatch({ type: 'RESET_PASS', status: false, actionCode: '' });
        });
        this.setState({
            reset1: '',
            reset2: ''
        });
    }

    BackResetPassword = () => event => {
        event.preventDefault();
        this.setState({
            forgotPass: false
        });
    }

    LoginUser = (email, password) => event => {
        event.preventDefault();
        if(email.toLowerCase()=="admin@superbrain.vn"&&password=="123")
        {
          let ADMINACCOUNT = {name:'Admin'};
            localStorage.setItem('ADMINACCOUNT', JSON.stringify(ADMINACCOUNT));
            store.dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT });
            this.forceUpdate();

        }
    }

    render() {
        const { classes } = this.props;
        const { email, password} = this.state;

        if(store.getState().ADMINACCOUNT)
        {
          return <Redirect to='/sbadmin' />
        }
        return (
            <div className={classes.root}>
                <div className={classes.middle}>

                    <Paper className={classes.square} elevation={4}>
                        <Typography variant="headline" color="primary">
                            Superbrain Admin
                        </Typography>
                        <div style={{ display: 'block', height: 5 }} />
                        <div>
                            <Grid container direction="column">
                                <Grid item xs>
                                    <TextField
                                        value={email}
                                        onChange={this.handleChange('email')}
                                        label="Email"
                                        autoFocus={true}
                                        placeholder="johndoe@example.com"
                                        fullWidth
                                        required
                                        type="text"
                                        margin="normal"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        value={password}
                                        onChange={this.handleChange('password')}
                                        label="Password"
                                        placeholder="******"
                                        fullWidth
                                        required
                                        type="password"
                                        margin="normal"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {this.state.MESSAGE ? <Typography style={{ color: '#F44336', marginTop: 10 }} variant="body2">{this.state.MESSAGE}</Typography> : ''}

                            <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginTop: 25 }}>
                                <Grid item>

                                </Grid>
                                <Grid item>
                                    <Button color="primary" size="medium" variant="raised" disabled={!email || !password} onClick={this.LoginUser(email, password)}>
                                            <LockIcon style={{ marginRight: 5 }} /> LOGIN
                                            </Button>
                                </Grid>
                            </Grid></div>

                    </Paper>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Login);
