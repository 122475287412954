import React from 'react';
import store from '../store';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FaceIcon from '@material-ui/icons/Face';
import StarsIcon from '@material-ui/icons/Stars';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';

import InputIcon from '@material-ui/icons/Input';
import DashboardIcon from '@material-ui/icons/Dashboard';


import firebase from 'firebase/app';

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
        maxWidth: 850,
        backgroundColor: '#fff',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3
    },
    menuButton: {
        marginLeft: 10,
        marginRight: 10,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'fixed'
    },
    drawerPaper: {
        position: 'relative',
        maxWidth: 220,
        color:"black"
    },
    linknav: {
        textDecoration: 'none'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        minWidth: 0
    },
    avatar: {
        backgroundColor: red[500],
    },
    toolbar: {
        height: theme.spacing.unit * 3
    }
});

class SimpleAppBar extends React.Component {
    constructor(pros) {
        super(pros);
        this.state = {
            drawer: false
        };
    }

    toggleDrawer = open => () => {
        this.setState({
            drawer: open
        });
    }

    LogoutUser = () => event => {
        event.preventDefault();
        localStorage.removeItem('ADMINACCOUNT');
        store.dispatch({ type: 'ADMINACCOUNT', ADMINACCOUNT:false });
        window.location = "/sbadmin/login"
    }

    handleChange = name => event => {
        event.preventDefault();
        this.setState({
            [name]: event.target.value,
        });
    }
    render() {
        const { classes } = this.props;
        const ADMINACCOUNT = store.getState().ADMINACCOUNT;

        return (
            <div className={classes.root}>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.toggleDrawer(true)}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ marginRight: 'auto' }}>
                                <a href="/sbadmin" style={{ textDecoration: 'none', color: '#fff' }}>
                                    <Typography variant="headline" color="inherit" noWrap >
                                        Admin Panel
                                    </Typography>
                                </a>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" color="inherit" style={{ display: 'inline-block' }}>Admin</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    className={classes.menuButton}
                                    onClick={this.LogoutUser()}
                                >
                                    <InputIcon />
                                </IconButton>
                            </Grid>


                        </Grid>
                    </Toolbar>
                </AppBar>
                <Drawer open={this.state.drawer} onClose={this.toggleDrawer(false)} classes={{ paper: classes.drawerPaper }}>
                                        <List component="nav">
                                            <ListItem>
                                                <Avatar aria-label="Recipe" className={classes.avatar}>
                                                    A
                                                </Avatar>

                                            </ListItem>
                                            <Divider />
                                            <div className={classes.toolbar} />

                                            <Link to='/sbadmin/course' className={classes.linknav} onClick={this.toggleDrawer(false)}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <AllInboxIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Course" />
                                                </ListItem>
                                            </Link>
                                            <Link to='#' className={classes.linknav} onClick={this.toggleDrawer(false)}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <FaceIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Students" />
                                                </ListItem>
                                            </Link>
                                            <Link to='/sbadmin/challenges' className={classes.linknav} onClick={this.toggleDrawer(false)}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <StarsIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Challenges" />
                                                </ListItem>
                                            </Link>
                                            <Link to='#' className={classes.linknav} onClick={this.toggleDrawer(false)}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <AssessmentIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Reports" />
                                                </ListItem>
                                            </Link>
                                            <Link to='#' className={classes.linknav} onClick={this.toggleDrawer(false)}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <SupervisorIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Demo Users" />
                                                </ListItem>
                                            </Link>





                                            <ListItem button onClick={this.LogoutUser()}>
                                                <ListItemIcon>
                                                    <InputIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Sign Out" />
                                            </ListItem>
                                        </List>
                                    </Drawer>
            </div >
        );
    }
}

SimpleAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleAppBar);
