import React from 'react';
import store from "../store";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase/app";
import { Link } from "react-router-dom";
import * as STATIC from '../config/static';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import SyncIcon from '@material-ui/icons/Sync';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
class TopBar extends React.Component {
    state = {

    };
    logoutClick() {

      let TOKEN = localStorage.getItem('TOKEN');
      if(TOKEN)
      {
        TOKEN = JSON.parse(TOKEN);
        if(TOKEN.id)
          firebase.firestore().collection("tokens").doc(TOKEN.id).delete();
          localStorage.removeItem('TOKEN');
      }

      localStorage.removeItem('ACCOUNT');
      store.dispatch({ type: 'ACCOUNT', ACCOUNT: false });


    }

    toggleLang(LANG)
    {
      let nLANG = 'en';
      if(LANG == 'en')
      nLANG = 'vi';
      this.props.i18n.changeLanguage(nLANG);
      localStorage.setItem('LANG',nLANG);
      store.dispatch({ type: 'LANG', LANG: nLANG });
    }
    render() {
        const { classes } = this.props;
        let width = this.props.width;
        if(this.props.width>980)
        width = 980;
        let HC = STATIC.calSize;
        let fLarge = width * 0.06;
        let fTitle = width * 0.04;
        let fNormal = width * 0.03;
        let fSmall = width * 0.02;
        let ACCOUNT = store.getState().ACCOUNT;
        let SOUND = store.getState().SOUND;
        let LANG = store.getState().LANG;
        let pathName = window.location.pathname.toLowerCase();
        console.log(pathName);
        return (
          <div >
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',color:STATIC.MyStyle.TEXT_COLOR}}>
            <div style={{backgroundColor:STATIC.MyStyle.BG_TRANS,display:'flex',flexDirection:'row',alignItems:'center',maxWidth:STATIC.DWIDTH,width:width,padding:HC(10,width)+'px '+HC(10,width)+'px'}}>
              <div style={{flexGrow:1}}>
              <Link to={'/'}>
                <img src="assets/web/logo.png" style={{ width: STATIC.calSize(100, width), height: "auto" }} />
              </Link>
            </div>
            <div style={{textAlign:'center',fontSize:fSmall}}>HOT LINE<br/>
            <a style={{fontSize:fNormal,backgroundColor:STATIC.MyStyle.BG_COLOR,padding:HC(5,width)+'px '+HC(10,width)+'px ',borderRadius:HC(10,width),border:'1px solid '+STATIC.MyStyle.TEXT_COLOR,color:STATIC.MyStyle.TEXT_COLOR}}><b>036.303.0066</b></a></div>
            </div>

          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',color:STATIC.MyStyle.TEXT_COLOR}}>
            <div style={{backgroundColor:STATIC.MyStyle.BG_TRANS,display:'flex',flexDirection:'row',alignItems:'center',maxWidth:STATIC.DWIDTH,width:width,padding:HC(10,width)+'px '+HC(10,width)+'px'}}>
              <ButtonBase onClick={()=>{ if(pathName=='/'||pathName=='/home') return; else this.props.history.push('/home'); }} focusRipple style={{ color: "white",marginRight:HC(10,width),position:'relative', display: 'flex',padding:HC(10,width)+'px '+HC(20,width)+'px',borderBottom:pathName=='/'||pathName=='/home'?'1px solid '+STATIC.MyStyle.TEXT_COLOR:'none'  }} color="primary" size="large" variant="contained"  >
                  <div><HomeIcon style={{fontSize:fSmall,marginRight:HC(10,width)}} /></div>
                  <div style={{fontSize:fSmall}}> Trang chủ</div>
                </ButtonBase>
                <ButtonBase onClick={()=>{ if(pathName=='/nhuongve'||pathName=='/ex_ticket') return; else this.props.history.push('/nhuongve'); }} focusRipple style={{ color: "white",marginRight:HC(10,width),position:'relative', display: 'flex',padding:HC(10,width)+'px '+HC(20,width)+'px',borderBottom:pathName=='/nhuongve'||pathName=='/ex_ticket'?'1px solid '+STATIC.MyStyle.TEXT_COLOR:'none'  }} color="primary" size="large" variant="contained"  >
                    <div><SyncIcon style={{fontSize:fSmall,marginRight:HC(10,width)}} /></div>
                    <div style={{fontSize:fSmall}}> Nhượng vé</div>
                  </ButtonBase>
                  <ButtonBase onClick={()=>{ if(pathName=='/muave'||pathName=='/buy_ticket') return; else this.props.history.push('/muave'); }} focusRipple style={{ color: "white",marginRight:HC(10,width),position:'relative', display: 'flex',padding:HC(10,width)+'px '+HC(20,width)+'px',borderBottom:pathName=='/muave'||pathName=='/buy_ticket'?'1px solid '+STATIC.MyStyle.TEXT_COLOR:'none'  }} color="primary" size="large" variant="contained"  >
                      <div><FlightTakeoffIcon style={{fontSize:fSmall,marginRight:HC(10,width)}} /></div>
                      <div style={{fontSize:fSmall}}> Đặt vé</div>
                    </ButtonBase>
            </div>

          </div>
        </div>

        );
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(STATIC.styles)(STATIC.translate('common')(TopBar));
